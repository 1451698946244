<template>
  <div class="cpt-water">
    <div class="headwater">
      <div class="lasthead">水质检测</div>
      <div @click="btnheadwater"
           class="righthead">关闭</div>
    </div>
    <div class="contentwater">
      <div class="detectiontype">
        <div class="lasttype">检测类型:</div>
        <div>
          <template>
            <el-radio-group @change="waterdatainterface"
                            text-color="#00daff"
                            fill="#00daff"
                            v-model="value">
              <el-radio v-for="item in watedatatype"
                        :key="item.id"
                        :label="item.id">{{item.testType}}</el-radio>
              <!-- <el-radio :label="6">黑臭水体</el-radio> -->
            </el-radio-group>
          </template>
        </div>
      </div>
      <div class="reference">
        <div class="referenceArea">图例参考区:</div>
        <div class="photobox">
          <div class="onedot">
            <div class="yuandian1 yuandian"></div>
            <div class="wuyuan">无</div>
          </div>
          <div class="twodot">
            <div class="yuandian2 yuandian"></div>
            <div class="wuyuan">轻</div>
          </div>
          <div class="threedot">
            <div class="yuandian3 yuandian"></div>
            <div class="wuyuan">中</div>
          </div>
          <div class="fourdot">
            <div class="yuandian4 yuandian"></div>
            <div class="wuyuan">重</div>
          </div>
        </div>
      </div>
      <div class="dailydata">
        <div class="centent">单期数据:</div>
        <div class="drop-downlist">
          <!-- <el-select v-model="radio"
                     class="input-excute"
                     placeholder="请选择执行状态"
                     :popper-append-to-body="false"
                     clearable>
            <el-option label="2021-08-17"
                       :value="2"></el-option>
            <el-option label="2021-08-16"
                       :value="3"></el-option>
          </el-select> -->
          <el-date-picker @change="waterdatainterface"
                          v-model="datetime"
                          type="date"
                          placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="tablebox">
        <div class="tablehead">空间统计信息</div>
        <div class="tablebody">
          <div class="degree"
               style="border-left: none;">长度</div>
          <div class="degree">总面积</div>
          <div class="degree">无污染</div>
          <div class="degree">轻度污染</div>
          <div class="degree">中度污染</div>
          <div class="degree">重度污染</div>
          <div class="area"
               style="border-left: none;">{{waterdata.riverLength}}(m)</div>
          <div class="area">{{waterdata.riverArea}}(平方米)</div>
          <div class="area">{{waterdata.riverFreeArea}}(平方米)</div>
          <div class="area">{{waterdata.riverLightArea}}(平方米)</div>
          <div class="area">{{waterdata.riverModerateArea}}(平方米)</div>
          <div class="area">{{waterdata.riverSevereArea}}(平方米)</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data () {
    return {
      datetime: '',
      value: 1,
      watedatatype: [],
      // waterdata: {
      //   //河道长度
      //   riverLength: '',
      //   //河道总面积（平方米）
      //   riverArea: '',
      //   //无污染河道面积（平方米）
      //   riverFreeArea: '',
      //   //轻度污染河道面积（平方米）
      //   riverLightArea: '',
      //   //中度污染河道面积（平方米）
      //   riverModerateArea: '',
      //   //重度污染河道面积（平方米）
      //   riverSevereArea: ''
      // }
      waterdata: []
    };
  },
  mounted () {
    // this.waterdatainterface()
    // this.Watetypedata()
  },
  methods: {
    // obtaindate () {
    //   // let date = this.datetime
    //   // var a = function (this.datetime) {
    //   //   var y = date.getFullYear();
    //   //   var m = date.getMonth() + 1;
    //   //   m = m < 10 ? '0' + m : m;
    //   //   var d = date.getDate();
    //   //   d = d < 10 ? ('0' + d) : d;
    //   //   return y + '-' + m + '-' + d;
    //   // };
    //   var changeDate = this.datetime.getFullYear() + '-' + (this.datetime.getMonth() + 1) + '-' + this.datetime.getDate()
    //   console.log("this.date", changeDate);
    // },
    async Watetypedata () {
      let res = await API.ACCIDENT.Watetype();
      this.watedatatype = res || []
    },
    async waterdatainterface () {
      var changeDate = this.datetime.getFullYear() + '-' + (this.datetime.getMonth() + 1) + '-' + this.datetime.getDate()
      let res = await API.ACCIDENT.Waterqualitydetection({
        testingType: this.value,
        testingDate: changeDate
      });
      this.waterdata = res || []
    },
    btnheadwater () {
      // this.$emit("guanbiwater")
      this.$store.commit('device/SET_WATERBOX', false)
    }
  },
};
</script>

<style lang="scss" scoped>
/* 选中后的字体颜色 */
::v-deep.el-radio__input.is-checked + .el-radio__label {
  color: #dce9ff !important;
}
::v-deep.el-radio .el-radio__label {
  color: #dce9ff !important;
}
// /* 选中后小圆点的颜色 */
// ::v-deep.el-radio__input.is-checked .el-radio__inner {
//   background: #28d4c1 !important;
//   border-color: #28d4c1 !important;
// }

.cpt-water {
  cursor: default;
  // position: absolute;
  // top: -100px;
  // left: -50%;
  // margin-left: -1000px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 459px;
  height: 418px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .headwater {
    height: 44px;
    width: 100%;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
    padding: 12px 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .lasthead {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
    }
    .righthead {
      font-family: MicrosoftYaHei;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .contentwater {
    padding: 25px;
    box-sizing: border-box;
    .detectiontype {
      display: flex;
      .lasttype {
        margin-right: 50px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #00daff;
        letter-spacing: 0;
        font-weight: 700;
      }
    }
    .reference {
      display: flex;
      margin-top: 23px;
      margin-bottom: 53px;
      .referenceArea {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #00daff;
        letter-spacing: 0;
        font-weight: 700;
        margin-right: 15px;
      }
      .photobox {
        display: flex;
        width: 290px;
        justify-content: space-around;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #dce9ff;
        line-height: 16px;
        font-weight: 400;
        .onedot {
          display: flex;
          .yuandian1 {
            background: #00d0ff;
          }
        }
        .twodot {
          display: flex;
          .yuandian2 {
            background: #38bf13;
          }
        }
        .threedot {
          display: flex;
          .yuandian3 {
            background: #ddbd00;
          }
        }
        .fourdot {
          display: flex;
          .yuandian4 {
            background: #38bf13;
            border: 1px solid #38bf13;
          }
        }
        .yuandian {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
    .dailydata {
      display: flex;
      margin-bottom: 20px;
      .centent {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #00daff;
        letter-spacing: 0;
        font-weight: 700;
        margin-right: 50px;
      }
      .drop-downlist {
        margin-top: -5px;
        ::v-deep {
          .el-input__inner {
            background-color: rgb(63, 77, 115) !important;
            color: #00daff !important;
          }
        }
        // ::v-deep {
        //   .el-select {
        //     height: 36px;
        //     width: 262px;
        //     margin-left: 50px;
        //     .el-input {
        //       height: 100%;
        //       width: 100%;
        //       .el-input__inner {
        //         height: 100%;
        //         background-color: transparent;
        //         box-sizing: border-box;
        //         background: rgba(13, 34, 79, 0.75);
        //         border: 1px solid #43deff;
        //         border-radius: 1.23px;
        //         padding: 0 14px 0px 19px;
        //         font-family: Microsoft YaHei;
        //         font-size: 12px;
        //         color: #43deff;
        //       }
        //       .el-input__suffix {
        //         right: 0;
        //         .el-input__suffix-inner {
        //           .el-input__icon {
        //             line-height: 25px;
        //             color: #43deff;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }
    .tablebox {
      height: 148px;
      width: 408px;
      border: 1px solid #00b6ff;
      border-radius: 10px;
      .tablehead {
        height: 50px;
        line-height: 50px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #00daff;
        letter-spacing: 0;
        font-weight: 700;
      }
      .tablebody {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        div {
          height: 49px;
          line-height: 49px;
          width: 68px;
          box-sizing: border-box;
          border: 1px solid #00b6ff;
          border-bottom: none;
          border-right: none;
        }
        .degree {
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #dce9ff;
          letter-spacing: 0;
          font-weight: 400;
        }
        .area {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #949699;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
}
</style>