const assetsPath = "./assets";

export default {
  type_list: [
    { id: 1, title: "巡查" },
    { id: 2, title: "预警" },
    { id: 3, title: "侦察" },
    { id: 4, title: "服务" },
    { id: 5, title: "救援" },
    { id: 6, title: "处置" },
    { id: 7, title: "打击" },
    { id: 8, title: "宣传" },
  ],
  list: [
    {
      title: "高清变焦相机",
      icon: require(`${assetsPath}/MMC_Gimbal_Z40.png`),
      name: "MMC_Gimbal_Z40",
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import("./MMC_Gimbal_Z40"),
    },
    // {
    //   title: "高清变焦相机",
    //   icon: require(`${assetsPath}/MMC_Gimbal_Z33N.png`),
    //   name: "MMC_Gimbal_Z33N",
    //   type: [1, 2, 3, 4, 5, 6, 8],
    //   component: () => import("./MMC_Gimbal_Z33N"),
    // },
    {
      title: "相机(Z30Pro)",
      icon: require(`${assetsPath}/MMC_Gimbal_Z40.png`),
      name: "MMC_Gimbal_Z30Pro",
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import("./MMC_Gimbal_Z30Pro"),
    },
    {
      title: "喊话器",
      icon: require(`${assetsPath}/MMC_Gimbal_P0_Pro.png`),
      name: "MMC_Gimbal_P0_Pro",
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import("./MMC_Gimbal_P0_Pro"),
    },
    {
      title: "探照灯",
      icon: require(`${assetsPath}/MMC_Gimbal_L50.png`),
      name: "MMC_Gimbal_L50",
      type: [3, 4],
      component: () => import("./MMC_Gimbal_L50"),
    },
    {
      title: "红外热成像",
      icon: require(`${assetsPath}/MMC_Gimbal_ZT1.png`),
      name: "MMC_Gimbal_ZT1",
      type: [3, 4, 5, 6],
      component: () => import("./MMC_Gimbal_ZT1"),
    },
    {
      title: "MMC_Gimbal_ZT60R",
      icon: require(`${assetsPath}/MMC_Gimbal_ZT60R.png`),
      name: "MMC_Gimbal_ZT60R",
      type: [7, 8],
      component: () => import("./MMC_Gimbal_ZT60R"),
    },
    {
      title: "抛投",
      icon: require(`${assetsPath}/MMC_Gimbal_S1.png`),
      name: "MMC_Gimbal_S1",
      type: [5, 6],
      component: () => import("./MMC_Gimbal_S1"),
    },
    // {
    //   title: "喷火器",
    //   icon: require(`${assetsPath}/MMC_Gimbal_FF6.png`),
    //   name: "MMC_Gimbal_FF6",
    //   type: [5],
    //   component: () => import("./MMC_Gimbal_FF6"),
    // },
    {
      title: "双光",
      icon: require(`${assetsPath}/MMC_Gimbal_ZT30N.png`),
      name: "MMC_Gimbal_ZT30N",
      type: [6],
      component: () => import("./MMC_Gimbal_ZT30N"),
    },
    {
      title: "高清变焦相机z20",
      icon: require(`${assetsPath}/MMC_Gimbal_Z40N.jpg`),
      name: "MMC_Gimbal_Z40N",
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import("./MMC_Gimbal_Z20"),
    },
    // {
    //   title: "霹雳火S79",
    //   icon: require(`${assetsPath}/MMC_Gimbal_S79.png`),
    //   name: "MMC_Gimbal_S79",
    //   type: [7],
    //   component: () => import("./MMC_Gimbal_S79"),
    // },
    {
      title: "催泪弹",
      icon: require(`${assetsPath}/MMC_Gimbal_FE8.png`),
      name: "MMC_Gimbal_FE8",
      type: [7, 8],
      component: () => import("./MMC_Gimbal_FE8"),
    },
    {
      title: "高清变焦相机Z40N",
      icon: require(`${assetsPath}/MMC_Gimbal_Z40N.jpg`),
      name: "MMC_Gimbal_Z40N",
      type: [1, 2, 3, 4, 5, 6, 8],
      component: () => import("./MMC_Gimbal_Z40N"),
    },

    {
      title: "MMC_Gimbal_Z60S",
      icon: require(`${assetsPath}/MMC_Gimbal_Z60S.png`),
      name: "MMC_Gimbal_Z60S",
      type: [7, 8],
      component: () => import("./MMC_Gimbal_Z60S"),
    },
  ],
};
