<template>
  <div class="big-box">
    <div class="big-boxs" ref="animation" style="z-index:0">
      <transition
        name="animate__animated dh"
        key="1"
        enter-active-class="animate__bounceInRight"
        leave-active-class="animate__bounceOutRight"
      >
        <div class="box-left" v-if="show">
          <div class="left-tb" v-if="VUE_APP_IP === '172.16.6.20' ">
            <div class="tb-box tb-box2" title="2D底图" @click="toggle(1)"> <span>2D</span></div>
          </div>
          <div class="left-tb" v-if="VUE_APP_IP === '172.16.6.20'">
            <div class="tb-box tb-box5" title="街道底图" @click="toggle(2)">
              <div class="tb-title">
                街道
              </div>
            </div>
          </div>
          <div class="left-tb" v-if="VUE_APP_IP != '172.16.6.20'">
            <div class="tb-box tb-box4" title="卫星地图" @click="toggle(4)"><div class="tb-title">卫星图</div></div>
          </div>
          <div class="left-tb">
            <Hwkeys style="display: block" />
          </div>
          
          <div class="left-tb" v-if="VUE_APP_IP != '172.16.6.20'">
            <div class="tb-box tb-box6" title="夜景地图" @click="toggle(3)">
              <div class="tb-title">
                夜景图
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div :class="getDitu()" title="切换底图" @click="keepit" >{{ditu == 1?'2D':''}}</div>
  </div>
</template>

<script>
import {mapGetters,mapActions} from "vuex"
import Hwkeys from "@/components/hwkeys";
export default {
  components: {
    Hwkeys,
  },
  computed:{
    ...mapGetters(["my_layers_data"]),
  },
  data() {
    return{
      show:false,
      ditu: 1,
      VUE_APP_IP: null
    }
  },
  methods:{
    ...mapActions("user",["Mylayers"]),
    keepit(){
      this.show = !this.show
    },
    toggle(data){
      if(data === 1){
        this.Mylayers('arcGIS1')
        this.ditu = 1
      } else if(data === 2){
        this.Mylayers('arcGIS')
        this.ditu = 2
      } else if(data === 3) {
        this.Mylayers('night')
        this.ditu = 3
      } else if(data === 4) {
        this.Mylayers('tian')
        this.ditu = 4
      }
      this.show = false
    },
    getDitu() {
      switch (this.ditu) {
        case 1:
          return 'box-right2'
          break;
        case 2:
          return 'box-right5'
          break;
        case 3:
          return 'box-right6'
          break;
        case 4: 
        return 'box-right4'
        default:
          break;
      }
    }
  },
  mounted(){
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP
    if(this.my_layers_data === 'arcGIS1' ){
      this.toggle(1)
    }else if (this.my_layers_data === 'arcGIS') {
      this.toggle(2)
    } else if (this.my_layers_data === 'night') {
      this.toggle(3)
    } else if (this.my_layers_data === 'tian') {
      this.toggle(4)
    }
  }
}
</script>



<style lang="scss" scoped>
  .big-box{
    overflow: hidden;
    // width: 316px;
    height: 50px;
    position: absolute;
    // z-index: 999;
    bottom: 40px;
    right: 45px;
    display: flex;
    .big-boxs{
      width: 190px;
      height: 100%;
      .box-left{
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background: linear-gradient(135deg, rgba(18, 43, 86, 0.7) 0%, rgba(2, 18, 47, 0.7) 100%);
        border-radius: 2px;
        margin-right: 10px;
        border: 1px solid #0075C2;
        .left-tb{
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #061938;
          border-radius: 5px;
          opacity: 0.7;
          border: 1px solid #4AADFF;
          cursor: pointer;
          .tb-box{
            width: 24px;
            height: 30px;
            background: url("~@/assets/basic-tools/tc.png") no-repeat;
          }
          .tb-box2{
            width: 100%;
            height: 100%;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4AADFF;
              text-align: center;
            }
          }
          .tb-box3{
            width: 100%;
            height: 100%;
            background: none;
          }
          .tb-box4{
            width: 100%;
            height: 100%;
            background: url("~@/assets/basic-tools/dt2.png") no-repeat;
            background-size: 69px;
            background-position: -12px -9px;
            .tb-title{
              width: 42px;
              height: 13px;
              background-color: #000000;         
              opacity: 0.64;
              border-radius: 0px 0px 4px 4px;
              font-size: 10px;
              font-family: MicrosoftYaHei;
              color: #FFFFFF;
              line-height: 10px;
              transform: scale(0.83);
              margin-top: 23px;
              margin-left: -3px;
              text-align: center;
            }
          }
          .tb-box5{
            width: 100%;
            height: 100%;
            background: url("~@/assets/basic-tools/jd.png") no-repeat;
            background-size: 69px;
            background-position: -12px -9px;
            .tb-title{
              width: 42px;
              height: 13px;
              background-color: #000000;         
              opacity: 0.64;
              border-radius: 0px 0px 4px 4px;
              font-size: 10px;
              font-family: MicrosoftYaHei;
              color: #FFFFFF;
              line-height: 10px;
              transform: scale(0.83);
              margin-top: 23px;
              margin-left: -3px;
              text-align: center;
            }
          }
          .tb-box6{
            width: 100%;
            height: 100%;
            background: url("~@/assets/basic-tools/night.png") no-repeat;
            background-size: 69px;
            background-position: -12px -9px;
            .tb-title{
              width: 42px;
              height: 13px;
              background-color: #000000;         
              opacity: 0.64;
              border-radius: 0px 0px 4px 4px;
              font-size: 10px;
              font-family: MicrosoftYaHei;
              color: #FFFFFF;
              line-height: 10px;
              transform: scale(0.83);
              margin-top: 23px;
              margin-left: -3px;
              text-align: center;
            }
          }
        }
      }
    }
    
  }
  ::v-deep .box-right6{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid #0075C2;
      background: url("~@/assets/basic-tools/night.png") 19% 33% no-repeat;
      background-size: 130% 103%;
      background-color:  rgba(18, 43, 86, 0.7);
    }
  ::v-deep .box-right5{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid #0075C2;
      background: url("~@/assets/basic-tools/jd.png") 19% 33% no-repeat;
      background-size: 130% 103%;
      background-color:  rgba(18, 43, 86, 0.7);
    }
    ::v-deep .box-right4{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid #0075C2;
      background: url("~@/assets/basic-tools/dt2.png") 19% 33% no-repeat;
      background-size: 130% 103%;
      background-color:  rgba(18, 43, 86, 0.7);
    }
  ::v-deep .box-right2{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid #0075C2;
      background-color:  rgba(18, 43, 86, 0.7);
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4AADFF;
      text-align: center;
      line-height: 46px;
    }
</style>